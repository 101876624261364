import React from "react";
import { ImGithub, ImInstagram, ImTwitter, ImMail2 } from "react-icons/im";

function Contact() {
  return (
    <div className="Home container d-flex justify-content-center align-items-center contactwrap text-center h-100 ">
      <div className="offset-0 text-start ">
        <a className="me-3 fs-2" href="https://github.com/yunushangoksu">
          <ImGithub /> <span className="contacttext">@yunushangoksu</span>
        </a>
        <br />
        <a className="me-3 fs-2" href="https://twitter.com/yunushangoksu">
          <ImTwitter /> <span className="contacttext">@yunushangoksu</span>
        </a>
        <br />
        <a
          className="me-3 fs-2"
          href="https://www.instagram.com/yunushangoksu/"
        >
          <ImInstagram /> <span className="contacttext">@yunushangoksu</span>
        </a>
        <br />
        <a className="me-3 fs-2" href="mailto:yunushangoksu@gmail.com">
          <ImMail2 />{" "}
          <span className="contacttext">yunushangoksu@gmail.com</span>
        </a>
      </div>
    </div>
  );
}

export default Contact;
