import React from "react";
function Home() {
  return (
    <div className="Home container">
      <div className="row mx-auto">
        <div className=" text-justify">
          <p className="fs-3 fw-semibold">Merhaba ben Yunushan</p>
          <p className="fs-4 fw-normal">
            Kendisini geliştirmeye çalışan bir yazılımcıyım.
          </p>
          <div className="w-75">
            <p className="fs-5 fw-light">
              İstanbul'da yaşayan, self-taught bir front-end developer adayıyım.
              Şu anda ise React frameworkünü kullanarak projeler geliştirmeye ve
              yeni teknolojilerle deneyimler edinmeye devam ediyorum. Yazdığım
              kodlara{" "}
              <a className="gitlink" href="https://github.com/yunushangoksu">
                GitHub
              </a>
              'ımdan erişebilirsin.
            </p>
            <p className="fs-5 fw-light">
              Şu an kullandığım teknolojiler; HTML, CSS, BootStrap, Tailwind,
              RestfulAPI, JavaScript, NodeJS. ReactJS.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
