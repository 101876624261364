import React from "react";

function Projects() {
  return (
    <div className="Home container ">
      <div className="row">
        <div className="col mb-3">
          <div className="card h-100 mx-auto" style={{ width: "18rem" }}>
            <img src="/e-commerce.png" className="card-img-top" alt="img" />
            <div className="card-body">
              <h5 className="card-title karttitle">e-commerce-app</h5>
              <p className="card-text kartinfo">
                Basit sepete ekle ve sepetten çıkar fonksiyonlarına sahip
                sadeleştirilmiş bir alışveriş sitesi uygulaması.
              </p>
              <a
                href="https://github.com/yunushangoksu/e-commerce-app"
                className="btn kartbuton"
              >
                Kaynak Kodları
              </a>
            </div>
          </div>
        </div>

        <div className="col mb-3">
          <div className="card h-100 mx-auto" style={{ width: "18rem" }}>
            <img src="/pokedexreadme.png" className="card-img-top" alt="img" />
            <div className="card-body">
              <h5 className="card-title karttitle">PokeDex</h5>
              <p className="card-text kartinfo">
                Api kullanarak verileri alıp ekrana düzenli bir şekilde yazdıran
                PokeDex uygulaması.
              </p>
              <a
                href="https://github.com/yunushangoksu/pokedex"
                className="btn kartbuton"
              >
                Kaynak Kodları
              </a>
            </div>
          </div>
        </div>

        <div className="col mb-3">
          <div className="card h-100 mx-auto" style={{ width: "18rem" }}>
            <img src="/pokedexreadme.png" className="card-img-top" alt="img" />
            <div className="card-body">
              <h5 className="card-title karttitle">Weather App</h5>
              <p className="card-text kartinfo">
                OpenWeather Api kullanarak geliştirdiğim bir hava durumu
                uygulaması
              </p>
              <a
                href="https://github.com/yunushangoksu/react-weather"
                className="btn kartbuton"
              >
                Kaynak Kodları
              </a>
            </div>
          </div>
        </div>

        <div className="col mb-3">
          <div className="card h-100 mx-auto" style={{ width: "18rem" }}>
            <img src="/yunushan.png" className="card-img-top" alt="img" />
            <div className="card-body  pb-3">
              <h5 className="card-title karttitle">yunushan.com</h5>
              <p className="card-text kartinfo">
                BootStrap ve React kullanarak yaptığım kişisel websitem.
              </p>
              <a
                href="https://github.com/yunushangoksu/yunushan"
                className="btn kartbuton"
              >
                Kaynak Kodları
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
