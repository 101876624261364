import React from "react";

function Footer() {
  return (
    <div className="footer d-flex justify-content-center align-items-center position-fixed bottom-0 w-100">
      2023 Istanbul
    </div>
  );
}

export default Footer;
