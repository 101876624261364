import "./App.css";
import Home from "./components/home";
import Contact from "./components/contact";
import Footer from "./components/footer";
import Projects from "./components/projects";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <div className="container">
          <div className="row">
            <div className="col mx-1 py-3 d-flex justify-content-between align-items-center text-nowrap">
              <Link className="text-decoration-none" to="/home">
                <img
                  className="me-2"
                  src="/favicon.ico"
                  alt="brand of sacriface"
                />
                Ana sayfa
              </Link>

              <div className="col mx-3 py-3 d-flex justify-content-end">
                <div>
                  <Link className="text-decoration-none" to="/projects">
                    Projeler
                  </Link>
                </div>
                <div className="ms-3">
                  <Link className="text-decoration-none" to="/contact">
                    İletişim
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/home" element={<Home />}></Route>

          <Route path="/projects" element={<Projects />}></Route>

          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
      </Router>

      <Footer />
    </div>
  );
}

export default App;
